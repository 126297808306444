/**
 * Footer component.
 * @module components/Projects/Projects
 */

import React from 'react';
import Projects from './Projects';

const View = ({ data }) => {
    return (
        <div>
            <Projects data={data} />
        </div>
    );
}

export default View;
