/**
 * Anontools component.
 * @module components/theme/Anontools/Anontools
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux'; 
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import config from '@plone/volto/registry';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
/**
 * Anontools container class.
 */
export class Anontools extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    content: PropTypes.shape({
      '@id': PropTypes.string,
    }),
    ccode: PropTypes.string
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
    content: {
      '@id': null,
    },
    ccode: null
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { settings } = config;
    return (
      !this.props.token && (
        <Menu pointing secondary floated="right">
          <Menu.Item>
            <Link
              aria-label="login"
              to={`/login${
                this.props.content?.['@id']
                  ? `?return_url=${this.props.content['@id'].replace(
                      settings.apiPath,
                      '',
                    )}` : '' }` + `${this.props.ccode !== null ? `?ccode=${this.props.ccode}`
                  : ''
                }`}
            >
              <FormattedMessage id="Log in" defaultMessage="Log in" />
            </Link>
          </Menu.Item>
          {settings.showSelfRegistration && (
            <Menu.Item>
              <Link aria-label="register" to="/register">
                <FormattedMessage id="Register" defaultMessage="Register" />
              </Link>
            </Menu.Item>
          )}
        </Menu>
      )
    );
  }
}
export default compose(
  withRouter,
  injectIntl,
  connect((state, props) => ({
      token: state.userSession.token,
      content: state.content.data,
      ccode: qs.parse(props.location.search).ccode,
    }),{Anontools}
  ,)
)(Anontools);
