import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProjects } from '../actions';
import 'remixicon/fonts/remixicon.css'
import { FolderContentAccordian  } from '../components/FolderContentAccordian';

const UserProjectsView = () => {


  const dispatch = useDispatch();
  const request = useSelector((state) => state.userProjects);
  const content = request?.undefined?.data?.items;

  const hasData = !!content;

  React.useEffect(() => {
    if (!hasData) dispatch(getUserProjects());
  }, [dispatch, hasData]);


  if (!hasData) {
    return <div><a href={`/login?return_url=/my-projects`}>Log in</a> to view projects </div>
  }

  return (
    <FolderContentAccordian folderContent={content} baseFolder={true}/>
  );
};

export default UserProjectsView;
