import React from 'react';
import ActiveReceiverMap from './ActiveReceiverMap';

const View = ({ data }) => {
    return (
        <div>
        <ActiveReceiverMap data={data}/>
        </div>
    );
}

export default View;
