import folderSVG from '@plone/volto/icons/table.svg';
import UserProjectsView  from './UserProject/UserProjectsView';
import UserProjectsEdit from './UserProject/UserProjectsEdit';
import { userProjects, userFolderContent } from './reducers';

const applyConfig = (config) => {
  config.addonReducers.userProjects = userProjects;
  config.addonReducers.userFolderContent = userFolderContent;
  config.blocks.blocksConfig.userProjects = {
    id: 'userProjects',
    title: 'User Projects',
    icon: folderSVG,
    group: 'common',
    view: UserProjectsView,
    edit: UserProjectsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },

  };
  return config;
};

export default applyConfig;
