import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import {  TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody, Table} from 'semantic-ui-react';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const DefaultTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  return (
    <>
      <div className="items">
      <Table celled fixed singleLine striped>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>Title</TableHeaderCell>
                    <TableHeaderCell>Description</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
        {items.map((item) => (
            <TableRow key={item['@id']}>
                <TableCell>
                    <ConditionalLink item={item} condition={!isEditMode}>
                        {item['@type'].toLowerCase() === 'document' ? <i className="ri-file-text-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'link' ? <i className="ri-links-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'news item' ? <i className="ri-news-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'image' ? <i className="ri-file-image-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'file' ? <i className="ri-file-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'event' ? <i className="ri-calendar-2-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'folder' ? <i className="ri-folder-line ficon"></i>:''}
                    {item.title ? item.title : item.id}
                    </ConditionalLink>
                </TableCell>
                <TableCell title={item.description} className="description_cell">{item.description}</TableCell>
            </TableRow>
        ))}
            </TableBody>
        </Table>
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
