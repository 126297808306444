import {
  AccordionTitle,
  AccordionContent,
} from 'semantic-ui-react'
import { useDispatch } from 'react-redux';
import React, {useState} from 'react';
import { getFolderContent } from '../actions';
import { FolderContentAccordian } from '../components/FolderContentAccordian';
import '../styles/accordianstyles.less'

export const Folder = ({index, folderInfo, isActive, clickHandler, baseFolder}) => {
  const [folderContent, setFolderContent] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  React.useEffect(()=>{
    if(isActive){
      const urlContent = folderInfo['@id'].split('/');
      const splitPoint = urlContent.indexOf('data');
      const apiUrl = urlContent.slice(splitPoint).join('/');

      dispatch(getFolderContent(apiUrl)).then(
        result => setFolderContent(result['items'])
      ).catch(function (err){
        console.error(err);
      }).finally(() => {
            setLoading(false)
        })
    }
  },[dispatch, isActive]);


  let actualURL = folderInfo['@id'];
  if (actualURL.split('/')[3] === 'api'){
    let urlParts = actualURL.split('/');
    urlParts.splice(3,1);
    actualURL = urlParts[0] + '//' + urlParts.slice(2).join('/');
  }

  let title = folderInfo.title;
  if (baseFolder === true){
    title = folderInfo.title + " " + actualURL.split('/').splice(-1);
    const collectionCode = actualURL.split('/').splice(-1).toString().toUpperCase();
    title = `${folderInfo.title} (${collectionCode})`;
  }

  let folderIcon = "ri-folder-fill";
  if(isActive){
    folderIcon = "ri-folder-open-fill";

  return (
    <>
        <AccordionTitle className='accordian-title' active={isActive} index={index} onClick={clickHandler}>
          <i className={folderIcon}></i>
          <a href={ actualURL }> { title }</a>
        </AccordionTitle>
        <AccordionContent active={isActive} className='accordian-indent' >
          <FolderContentAccordian folderContent={folderContent} loading={loading} baseFolder={false}/>
        </AccordionContent>
    </>
    );
  }


  return (
    <>
        <AccordionTitle className='accordian-title' active={isActive} index={index} onClick={clickHandler}>
          <i className={folderIcon}></i>
          <a href={ actualURL }> { title }</a>
        </AccordionTitle>
        <AccordionContent active={isActive}>
        </AccordionContent>
    </>
  );
}
