import React from 'react';
import SpeciesStats from './SpeciesStats';

const View = ({ data }) => {
    return (
        <div>
        <SpeciesStats data={data}/>
        </div>
    );
}

export default View;
