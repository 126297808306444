import icon from '@plone/volto/icons/world.svg';

import ProjectsBlockView from './Projects/BlockView';
import SingleProjectBlockView from './SingleProject/BlockView';
import StatisticsHeaderBlockView from './Statistics/HeaderBlockView';
import SpeciesStatsBlockView from './Statistics/SpeciesBlockView';
import TypeOfDetectionsBlockView from './Statistics/TypeOfDetectionsBlockView';
import DeployByWeekBlockView from './Statistics/DeployedByWeekBlockView';
import ActiveReceiverMapBlockView from './Statistics/ActiveReceiverMapBlockView';

import { projects } from './reducers';

// (Multi) Projects view
export default config => {
  config.addonReducers.projects = projects;
  config.blocks.blocksConfig.active_receiver_map_view = {
    id: 'active_receiver_map_view',
    title: 'Active Receiver Map',
    view: ActiveReceiverMapBlockView,
    icon:icon,
    group:'otn',
    restricted: false,
    mostUsed: false, 
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  config.blocks.blocksConfig.deploy_by_week_view = {
    id: 'deploy_by_week_view',
    title: 'Deployed By Week Stats',
    view: DeployByWeekBlockView,
    icon:icon,
    group:'otn',
    restricted: false,
    mostUsed: false, 
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  config.blocks.blocksConfig.species_stats_view = {
    id: 'species_stats_view',
    title: 'Species Stats',
    view: SpeciesStatsBlockView,
    icon:icon,
    group:'otn',
    restricted: false,
    mostUsed: false, 
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  config.blocks.blocksConfig.type_of_detections_view = {
    id: 'type_of_detections_view',
    title: 'Type of Detections',
    view: TypeOfDetectionsBlockView,
    icon:icon,
    group:'otn',
    restricted: false,
    mostUsed: false, 
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  config.blocks.blocksConfig.single_project_viewer = {
    id: 'single_project_viewer',
    title: 'Single Project',
    view: SingleProjectBlockView,
    icon:icon,
    group:'otn',
    restricted: false,
    mostUsed: false, 
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  config.blocks.blocksConfig.statistics_header = {
    id: 'statistics_header',
    title: 'Statistics Header',
    view: StatisticsHeaderBlockView,
    icon: icon,
    group:'otn',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security:{
      addPermission: [],
      view: [],
    },
  },
  config.blocks.blocksConfig.project_viewer = {
    id: 'project_viewer',
    title: 'Projects',
    // edit: ProjectsBlockEdit,
    view: ProjectsBlockView,
    icon: icon,
    group: 'otn',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    nodes: [{key: 1, fullnames:["OTN"], relation: "Node", name: "otn", color:'#4AA0FF', projsite:(node, ccode)=>{return(`project?ccode=${ccode}`)}},
            {key: 2, fullnames:["NEP"], relation: "Node",  name:"nep", color:'#2B7E7E', projsite:(node, ccode)=>{return(`project?ccode=${node+'.'}${ccode.replace(node+'.','')}`)}},
            {key: 3, fullnames:["SAF"], relation: "Node",  name:"saf", color:'#9D3581', projsite:(node, ccode)=>{return(`project?ccode=${node+'.'}${ccode.replace(node+'.','')}`)}},
            {key: 4, fullnames:["FACT"], relation: "Node", name:"fact", color:'#FF5353', projsite:(node, ccode)=>{return(`project?ccode=${node+'.'}${ccode.replace(node+'.','')}`)}},
            {key: 5, fullnames:["BRZ", "OTN-BR"], relation: "Node",  name:"brz", color:'#FFA52A', projsite:(node, ccode)=>{return(`project?ccode=${'BRZ.'+ccode.replace('OTN-BR.','').replace('BRZ.','')}`)}},
            {key: 6, fullnames:["ACT"], relation: "Node",  name:"act", color:'#019600', projsite:(node, ccode)=>{return(`project?ccode=${node+'.'}${ccode.replace(node+'.','')}`)}},
            {key: 7, fullnames:["MIGRAMAR"], relation: "Node",  name:"migramar", color:'#BFA912', projsite:(node, ccode)=>{return(`project?ccode=${node+'.'}${ccode.replace(node+'.','')}`)}},
            {key: 8, fullnames:["PATH"], relation: "Node",  name:"path", color:'#6C4C21', projsite:(node, ccode)=>{return(`project?ccode=${node+'.'}${ccode.replace(node+'.','')}`)}},
            {key: 9, fullnames:["PIRAT"], relation: "Node",  name:"pirat", color:'#000000', projsite:(node, ccode)=>{return(`project?ccode=${node+'.'}${ccode.replace(node+'.','')}`)}},
            {key: 10, fullnames:["ETN"], relation: "Data Partner",  name:"etn", color:'#4E2D7A', projsite:(node, ccode, url)=>{return(`${url.replace('&show=json','')}`)}},
            {key: 11, fullnames:["IMOS"], relation: "Data Partner",  name:"imos", color:'#1D5573', projsite:(node, ccode)=>{return(`https://imos.org.au/facilities/animaltracking/`)}},
            {key: 12, fullnames:["GLATOS"], relation: "Data Partner",  name:"glatos", color:'#4cbb17', projsite:(node, ccode)=>{return(`https://glatos.glos.us//home/project/${ccode}`)}},
          ],
  };
  return config
}
