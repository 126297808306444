import 'remixicon/fonts/remixicon.css'
import '../styles/accordianstyles.less'

export const File = ({fileInfo}) => {
  let actualURL = fileInfo['@id'];
  if (actualURL.split('/')[3] === 'api'){
    let urlParts = actualURL.split('/');
    urlParts.splice(3,1);
    actualURL = urlParts[0] + '//' + urlParts.slice(2).join('/');
  }

  return  (
    <>
      <div class='file'>
        <i className="ri-file-line"></i>
        <a href={ actualURL }> { fileInfo.title }</a>
      </div>
    </>
  );
}
