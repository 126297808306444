import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTypeOfDetections } from '../actions/Statistics/stats';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { List, ListItem, ListContent } from 'semantic-ui-react';
import {Container} from 'semantic-ui-react';
import numeral from 'numeral';

const color_map = {'TRANSMITTER':'#0088FE', 'ANIMAL':'#00C49F', 'UNQUALIFIED':'#FFBB28', 'TEST':'#FF8042'};

function ColorLegend({name, code}){
  return (
    <ListItem style={{"display": "flex"}}>
  <svg style={{"margin": "5px"}}
    width="10.574801"
    height="10.574801"><defs
      id="defs1" />
       <g transform="matrix(3.7795276,0,0,3.7795276,-166.56627,-133.36815)"><rect
        style={{"fill":color_map[code], stroke:"#cccccc", "strokeWidth": 0.264583, 
                "strokeLinecap":"round", "strokeDasharray":"none"}}
        id="rect1"
        width="2.5333333"
        height="2.5333333"
        x="44.20295"
        y="35.419281"
        rx="0.71657902"
        ry="0.51015848"/></g></svg>
        <ListContent style={{"fontSize": "medium"}}>{name}</ListContent>
        </ListItem>
        )
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${numeral(value).format('0.0a')}`}
    </text>
  );
};

const TypeOfDetections = (props) => {
    const { data } = props;
    const [Detections, setDetections] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();

    async function getDetectionStats(){
        dispatch(getTypeOfDetections(location.pathname)).then(
          result => setDetections(result)
        ).catch(function (err) {
          console.info(err)
        });
    };

    React.useEffect(() => {
        getDetectionStats();
    }, []);
    return (
      <div className="ui container stackable two column grid">
        <div className="eight wide column">
          <ResponsiveContainer width="100%" height="100%" minHeight={"600px"}>  
            <PieChart width={400} height={400}>
              <Pie
                data={Detections}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={180}
                fill="#8884d8"
                dataKey="value"
                label={renderCustomizedLabel}
                >
                {Detections.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={color_map[entry.name]} />
                  ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="four wide column">
          <div className="ui segment">
          <List>
            <ColorLegend name="Animal" code="ANIMAL"/>
            <ColorLegend name="Transmitter" code="TRANSMITTER"/>
            <ColorLegend name="Mystery Tag" code="UNQUALIFIED"/>
            <ColorLegend name="Test" code="TEST"/>

          </List>
          </div>
        </div>
      </div>
    );
};

export default TypeOfDetections
