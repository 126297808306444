import { GET_USER_PROJECTS, GET_FOLDER_CONTENT } from './constants';

export function getUserProjects(headers = {}) {
  return {
    type: GET_USER_PROJECTS,
    request: {
      op: 'get',
      path: `/data/repository/@search?sort_on=shortname&b_size=2000&path.depth=1`,
      headers,
    },
  };
}

export function getFolderContent(url){
  return {
    type: GET_FOLDER_CONTENT,
    request: {
      op: 'get',
      path: `${url}/@search?b_size=100&path.depth=1`,
    }
  };
}
