import React, { Suspense, lazy, useState } from 'react';
import {
  Container,
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Label,
  Card,
  List,
  ListItem,
  ListContent,
  ListIcon,
  Image,
} from 'semantic-ui-react';
import '../style/statistics_header.less';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSpeciesStatistics } from '../actions/Statistics/stats';
import {
  BarChart,
  Cell,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import 'remixicon/fonts/remixicon.css'

const color_map = {cr: "red",
en: "orange",
vu: "yellow",
nt: "olive",
lc: "green",
dd: "teal",
nr: "blue",
ne: "grey",
}

function ColorLegend({name, code}){
  return (
    <ListItem style={{"display": "flex"}}>
  <svg style={{"margin": "5px"}}
    width="10.574801"
    height="10.574801"><defs
      id="defs1" />
       <g transform="matrix(3.7795276,0,0,3.7795276,-166.56627,-133.36815)"><rect
        style={{"fill":color_map[code], stroke:"#cccccc", "strokeWidth": 0.264583, 
                "strokeLinecap":"round", "strokeDasharray":"none"}}
        id="rect1"
        width="2.5333333"
        height="2.5333333"
        x="44.20295"
        y="35.419281"
        rx="0.71657902"
        ry="0.51015848"/></g></svg>
        <ListContent style={{"fontSize": "medium"}}>{name}</ListContent>
        </ListItem>
        )
}

function CustomTooltip({ payload, label, active }) {
  if (active && payload[0] != undefined) {
    const status_code = payload[0].payload['iucn_status'];
    let status = '';
    let count = 0;
    let color = 'grey';
    
    switch (status_code) {
      case 'CR':
        status = 'Critically Endangered';
        count = payload[0].payload['cr'];
        color = color_map["cr"];
        break;
      case 'EN':
        status = 'Endangered';
        count = payload[0].payload['en'];
        color = color_map["en"];
        break;
      case 'VU':
        status = 'Vulnerable';
        count = payload[0].payload['vu'];
        color = color_map["vu"];
        break;
      case 'NT':
        status = 'Near Threatened';
        count = payload[0].payload['nt'];
        color = color_map["nt"];
        break;
      case 'LC':
        status = 'Least Concern';
        count = payload[0].payload['lc'];
        color = color_map["lc"];
        break;
      case 'DD':
        status = 'Data Deficient';
        count = payload[0].payload['dd'];
        color = color_map["dd"];
        break;
      case 'No Result':
        status = 'No Result';
        count = payload[0].payload['nr'];
        color = color_map["nr"];
        break;
      case 'No Evaluation':
        status = 'No Evaluation';
        count = payload[0].payload['ne'];
        color = color_map["ne"];
        break;
    }
    return (
    <Card color={color}>
    <CardContent>
      <CardHeader>{label}</CardHeader>
      <CardMeta>
      <Label color={color}>
        <span>{status}</span>
      </Label>
      </CardMeta>
    </CardContent>
    <CardContent extra>
      <a>
        <i className="ri-price-tag-3-line"></i>
        {count} tagged
      </a>
    </CardContent>
  </Card>
    );
  }

  return null;
}

const SpeciesStats = (props) => {
    const { data } = props;
    const [species, setSpecies] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();

    async function getSpeciesStats(){
        dispatch(getSpeciesStatistics(location.pathname)).then(
          result => setSpecies(result)
        ).catch(function (err) {
          console.info(err)
        });
    };

    React.useEffect(() => {
        getSpeciesStats();
    }, []);
    return (
    <Container>
      <div className="ui container stackable two column grid">
        <div className="nine wide column">
          <ResponsiveContainer width="100%" height="100%" minHeight={"600px"}>
            <BarChart
              width={500}
              height={600}
              data={species}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 240,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis fontSize={12} padding={{ left: 10, right:10 }} dataKey="name" 
                angle="90" textAnchor="start"/>
              <YAxis  fontSize={14} allowDataOverflow={true} scale="log" domain={[1.0, 'auto']}/>
              <Tooltip animationDuration={0} content={CustomTooltip} />
              <ReferenceLine y={0} stroke="#000" />
              <Brush y={2} dataKey="name" height={30} stroke="#8884d8" />
              <Bar dataKey="cr" fill={color_map['cr']} stackId="a" />
              <Bar dataKey="en" fill={color_map['en']} stackId="a" />
              <Bar dataKey="vu" fill={color_map['vu']} stackId="a" />
              <Bar dataKey="dd" fill={color_map['dd']} stackId="a" />
              <Bar dataKey="nt" fill={color_map['nt']} stackId="a" />
              <Bar dataKey="lc" fill={color_map['lc']} stackId="a" />
              <Bar dataKey="ne" fill={color_map['ne']} stackId="a" />
              <Bar dataKey="nr" fill={color_map['nr']} stackId="a" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="three wide column"><div className="ui segment">
        <List>
          <ColorLegend name="Critically Endangered" code="cr"/>
          <ColorLegend name="Endangered" code="en"/>
          <ColorLegend name="Vulnerable" code="vu"/>
          <ColorLegend name="Near Threatened" code="nt"/>
          <ColorLegend name="Least Concern" code="lc"/>
          <ColorLegend name="Data Deficient" code="dd"/>
          <ColorLegend name="No Result" code="nr"/>
          <ColorLegend name="No Evaluation" code="ne"/>
        </List>
        </div></div>
      </div>
    </Container>
    );
};

export default SpeciesStats
