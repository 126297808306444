/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import CookieBanner from 'volto-cookie-banner/CookieBannerContainer';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    // bbb_getContentFetchesFullobjects: true,
    isMultilingual: false,
    ga4_key: 'G-V4TTZ7SJV0',
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    appExtras: [
      {
        match: '',
        component: CookieBanner,
      },
    ],
  };
  config.blocks.requiredBlocks = [];
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
    },
  };
  return config;
}
