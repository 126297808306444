/**
 * @module components/SingleProject/Project
 */

import React from 'react';
import Project from './Project';

const View = ({ data }) => {
    return (
        <div>
            <Project data={data} />
        </div>
    );
}

export default View;
