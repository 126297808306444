import React from 'react';
import loadable from '@loadable/component';

const SingleMap = loadable(() => import('./SingleMap'), {
    ssr: false,
  });

const Project = (props) => {
    const { data } = props;
    
    return (
        <SingleMap data={data} />
    );
};

export default Project
