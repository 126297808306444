import React from 'react';
import UserProjectsView from './UserProjectsView';

const UserProjectsEdit = (props) => {
  return (
    <div>
      <UserProjectsView {...props} />
    </div>
  );
};

export default UserProjectsEdit;
