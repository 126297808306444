import React, { useState } from 'react';
import loadable from '@loadable/component';

const ActiveMap = loadable(() => import('./ActiveMap'), {
    ssr: false,
  });

const ActiveReceiverMap = (props) => {
    const { data } = props;
    return(
        <ActiveMap data={data} />
    );
}

export default ActiveReceiverMap;
