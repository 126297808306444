
import { GET_HEADER_STATS, GET_SPECIES_STATS, GET_TYPE_OF_DETECTIONS, GET_DEPLOYED_BY_WEEK} from '../../constants/ActionTypes';

export function getHeaderStatistics(url) {
    return {
      type: GET_HEADER_STATS,
      request: {
        op: 'get',
        path: `${url}/header_stats.json`,
      },
    };
};

export function getSpeciesStatistics(url) {
  return {
    type: GET_SPECIES_STATS,
    request: {
      op: 'get',
      path: `${url}/species_stats.json`,
    },
  };
};

export function getTypeOfDetections(url){
  return {
    type: GET_TYPE_OF_DETECTIONS,
    request: {
      op: 'get',
      path: `${url}/types_of_detections.json`,
    },
  };
};

export function getDeployedByWeek(url){
  return {
    type: GET_DEPLOYED_BY_WEEK,
    request: {
      op: 'get',
      path: `${url}/deployed_by_week.json`,
    },
  };
};