export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_BY_CODE = 'GET_PROJECTS_BY_CODE';
export const GET_PROJECTS_BY_CONTACT = 'GET_PROJECTS_BY_CONTACT';
export const GET_PROJECTS_BY_COUNTRY = 'GET_PROJECTS_BY_COUNTRY';
export const GET_PROJECTS_BY_SPECIES = 'GET_PROJECTS_BY_SPECIES';
export const GET_PROJECTS_BY_NODE = 'GET_PROJECTS_BY_NODE';
export const GET_PROJECT_SUMMARY = 'GET_PROJECT_SUMMARY';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_ACTIVE_COUNTRIES = 'GET_ACTIVE_COUNTRIES';
export const GET_SPECIES = 'GET_SPECIES';
export const GET_SINGLE_PROJECT_BY_CODE = 'GET_SINGLE_PROJECT_BY_CODE';
export const GET_PROJECTS_BY_INSTITUTIONS = 'GET_PROJECTS_BY_INSTITUTIONS';
export const GET_INSTITUTIONS = 'GET_INSTITUTIONS';
export const GET_HEADER_STATS = 'GET_HEADER_STATS';
export const GET_SPECIES_STATS = 'GET_SPECIES_STATS';
export const GET_TYPE_OF_DETECTIONS = 'GET_TYPE_OF_DETECTIONS';
export const GET_DEPLOYED_BY_WEEK = 'GET_DEPLOYED_BY_WEEK';
export const GET_ACTIVE_RECEIVERS = 'GET_ACTIVE_RECEIVERS';