import React from 'react';
import DeployedByWeek from './DeployedByWeek';

const View = ({ data }) => {
    return (
        <div>
        <DeployedByWeek data={data}/>
        </div>
    );
}

export default View;
