const initialState = {
    loaded: false,
    loading: false,
    error: null,
    data: {},
  };
  
  import { GET_PROJECTS } from '../constants/ActionTypes';

export default function projects(state = initialState, action = {}) {  
    switch (action.type) {
      case `${GET_PROJECTS}_PENDING`:
        return {
          ...state,
          error: null,
          loaded: false,
          loading: true,
        };
  
      case `${GET_PROJECTS}_SUCCESS`:
        return {
          ...state,
          data: action.result,
          error: null,
          loaded: true,
          loading: false,
  
        };
  
      case `${GET_PROJECTS}_FAIL`:
        return {
          ...state,
          error: action.error,
          loaded: false,
          loading: false,
        };
      default:
        return state;
    }
  }