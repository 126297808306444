import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDeployedByWeek } from '../actions/Statistics/stats';
import { PieChart, Pie, Label, Tooltip, ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import { List, ListItem, ListContent } from 'semantic-ui-react';
import {Container} from 'semantic-ui-react';
import numeral from 'numeral';

const COLORS = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];
const MONTHS = {3:"January", 7:"February", 11:"March", 15:"April", 19:"May", 23:"June", 27:"July", 31:"August",
                35:"September", 39:"October", 43:"November", 47:"December"}

function ColorLegend({name, idx}){
    return (
        <ListItem style={{"display": "flex"}}>
    <svg style={{"margin": "5px"}}
        width="10.574801"
        height="10.574801"><defs
        id="defs1" />
            <g transform="matrix(3.7795276,0,0,3.7795276,-166.56627,-133.36815)"><rect
            style={{"fill":idx, stroke:"#cccccc", "strokeWidth": 0.264583, 
                    "strokeLinecap":"round", "strokeDasharray":"none"}}
            id="rect1"
            width="2.5333333"
            height="2.5333333"
            x="44.20295"
            y="35.419281"
            rx="0.71657902"
            ry="0.51015848"/></g></svg>
            <ListContent style={{"fontSize": "medium"}}>{name}</ListContent>
            </ListItem>
            )
    }

const DeployByWeek = (props) => {
    const { data } = props;
    const [DeployedByWeek, setDeployedByWeek] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();
    const [minYear, setMinYear] = useState([2003]);
    let maxYear = new Date().getFullYear() ; 

    function range(size, startAt = 0) {
        return [...Array(size).keys()].map(i => i + startAt);
    }

    async function getDeployedByWeekStats(){
        dispatch(getDeployedByWeek(location.pathname)).then(
          result => 
          //Transpose the data
          {let final = Array(52);
           for (let row = 0; row < 52; row++) {
              final[row] = {};
              if (row in MONTHS){
                final[row]['name'] = MONTHS[row];
              }else{
                final[row]['name'] = row;
              }
              for (let year in result) {
                if (year == 0){
                    setMinYear(result[year]['year']);
                }
                final[row][result[year]['year']] = result[year]['wk_' + row];
              }
            }
            setDeployedByWeek(final)}
        ).catch(function (err) {
          console.info(err)
        });
    };

    React.useEffect(() => {
        getDeployedByWeekStats();
    }, []);

    return(
    <div className="ui container stackable two column grid">
        <div className="ten wide column">
            <ResponsiveContainer width="100%" height="100%" minHeight={"600px"}>
                <LineChart width={500} height={300} data={DeployedByWeek} margin={{
                        top: 50,
                        right: 0,
                        left: 0,
                        bottom: 64,
                    }}>
                <XAxis dataKey="name" padding={{ left: 10, right:10 }} angle="45" textAnchor="start" fontSize={12}
                       ticks={Object.values(MONTHS)}>
                        <Label value="Months" offset={-32} position="insideBottom" />
                        </XAxis>
                <YAxis padding={{ top: 10, bottom:10 }}/>
                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <Tooltip />
                {range((maxYear+1)-minYear, minYear).map((year) => {
                    return(
                        <Line key={year} type="monotone" dataKey={year} stroke={COLORS[year % 20]}/>
                    )
                })}
                </LineChart>
            </ResponsiveContainer>
        </div>
        <div className="two wide column">
            <div className="ui segment">
            <List>
            {range((maxYear+1)-minYear, minYear).map((year) => {
                return(
                    <ColorLegend key={year} name={year} idx={COLORS[year % 20]}/>
                )
            })}
            </List>
            </div>
        </div>
    </div>)
}


export default DeployByWeek;
